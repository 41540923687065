//
// Nanum Myeongjo fonts
//

@font-face {
  font-family: 'NanumMyeongjo';
  font-weight: normal;
  src:	local('※'),
  		url(../fonts/NanumMyeongjo-Regular.woff2) format('woff2'),
	    url(../fonts/NanumMyeongjo-Regular.woff) format('woff'), 
	    url(../fonts/NanumMyeongjo-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'NanumMyeongjo';
  font-weight: 600;
  src:	local('※'),
  	 	url(../fonts/NanumMyeongjo-Bold.woff2) format('woff2'),
	    url(../fonts/NanumMyeongjo-Bold.woff) format('woff'), 
	    url(../fonts/NanumMyeongjo-Bold.otf) format('opentype');
}
@font-face{
font-family:"NanumMyeongjo";
font-weight: 700;
src: local('※'),
    url(../fonts/NanumMyeongjo-ExtraBold.woff) format('woff'),
    url(../fonts/NanumMyeongjo-ExtraBold.woff2) format('woff2'),
    url(../fonts/NanumMyeongjo-ExtraBold.ttf) format('truetype');
}