//
// datatable.scss
//

.dataTables_wrapper {
    &.container-fluid {
        padding: 0;
    }
}

table.dataTable {
    border-collapse: collapse !important;
    margin-bottom: 15px !important;

    // Change icons view
    thead {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled{
            &:before {
                right: 0.5rem;
                content: "\F35D";
                font-family: "Material Design Icons";
                font-size: 1rem;
                top: 18px;
            }
            &:after {
                right: 0.5em;
                content: "\F360";
                font-family: "Material Design Icons";
                top: 12px;
                font-size: 1rem;
            }
        }
    }

    tbody {
        // Multi select table

        > tr.selected, >tr>.selected {
            background-color: rgba($primary, 0.15);
            
            td {
                border-color: rgba($primary, 0.15);
            }
        }
        td {
            &:focus {
                outline: none !important;
            }
        }
        // Key Tables
        th.focus,td.focus{
            outline: 2px solid $primary !important;
            outline-offset: -1px;
            background-color: rgba($primary, 0.15);
        }
    }
}

.dataTables_info {
    font-weight: $font-weight-semibold;
}


// Responsive data table
table.dataTable.dtr-inline.collapsed {
    > tbody {
        >tr[role=row] {
            > td, > th {
                &:first-child{
                    &:before{
                        box-shadow: $shadow-lg;
                        background-color: $success;
                        top: auto;
                        bottom: auto;
                    }
                }
            }
        }
        >tr.parent {
            > td, > th {
                &:first-child{
                    &:before{
                        background-color: $danger;
                    }
                }
            }
        }
    }
}


// Data Table copy button
div.dt-button-info {
    background-color: $primary;
    border: none;
    color: $white;
    box-shadow: none;
    border-radius: 3px;
    text-align: center;
    z-index: 21;

    h2 {
        border-bottom: none;
        background-color: rgba($white, 0.2);
        color: $white;
    }
}

@include media-breakpoint-down(sm) {
    li.paginate_button.previous,li.paginate_button.next {
        display: inline-block;
        font-size: 1.5rem;
    }
 
    li.paginate_button {
        display: none;
    }
    .dataTables_paginate {
        ul {
            text-align: center;
            display: block;
            margin: $spacer 0 0 !important;
        }
    }
    div.dt-buttons {
        display: inline-table;
        margin-bottom: $spacer;
    }
}