//
// YiSunShin Dotum fonts
//

@font-face {
  font-family: 'YiSunShinDotum';
  font-weight: 400;
  font-style: normal;
  src: local('※');
  src: url('../fonts/YiSunShin_Dotum_M.woff') format('woff'),
  url('../fonts/YiSunShin_Dotum_M.ttf') format('truetype');
}
@font-face {
  font-family: 'YiSunShinDotum';
  font-weight: 300;
  font-style: normal;
  src: local('※');
  src: url('../fonts/YiSunShin_Dotum_L.ttf') format('truetype');
}
@font-face {
  font-family: 'YiSunShinDotum';
  font-weight: 700;
  font-style: normal;
  src: local('※');
  src: url('../fonts/YiSunShin_Dotum_B.ttf') format('truetype');
}
